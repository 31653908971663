import type { StyleRule } from '@archipro-design/aria';
import { profileNavigationItemClassName } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const MobileNavTabWrapper: StyleRule = ({ theme }) => {
    return {
        overflowY: 'hidden',
        height: pxToRem(60),
        margin: pxArrayToRem([31, 0, 40, 0]),
        textTransform: 'uppercase',
        [`& a.${profileNavigationItemClassName}`]: {
            height: pxToRem(48),
            margin: 0,
            '& > div': {
                letterSpacing: theme.siteVariables.letterSpacingSmall,
            },

            '&.active': {
                border: 0,
                backgroundColor: theme.siteVariables.colors.charcoal['000'],
            },
        },
        [`& a.${profileNavigationItemClassName}:first-child`]: {
            marginLeft: pxToRem(18),
        },
        [`& a.${profileNavigationItemClassName}:last-of-type`]: {
            marginRight: pxToRem(18),
        },
    };
};
