import type { StyleRule } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const NavContainerWrapper: StyleRule = () => ({
    gap: pxToRem(47),
});

export const NavItem: StyleRule = ({ theme }) => ({
    [`& .${captionClassName}`]: {
        color: theme.siteVariables.colorScheme.default?.foregroundDisabled,
    },
    [`&:hover`]: {
        [`& .${captionClassName}`]: {
            color: theme.siteVariables.colorScheme.default?.foreground,
        },
    },
});

export const NavItemActive: StyleRule = ({ theme }) => ({
    position: 'relative',
    [`& .${captionClassName}`]: {
        color: theme.siteVariables.colorScheme.default?.foreground,
        fontWeight: 500,
    },

    [`&::after`]: {
        position: 'absolute',
        content: "''",
        bottom: pxToRem(-29),
        width: '100%',
        height: '4px',
        background: theme.siteVariables.colors.charcoal[250],
        zIndex: theme.siteVariables.zIndexes.menuItem,
    },
});
