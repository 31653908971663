import type { StyleRule } from '@archipro-design/aria';
import { HeaderClassName } from '@archipro-design/aria';
import { flexClassName } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem, buttonClassName } from '@archipro-design/aria';

export const GetInTouchWrapper: StyleRule = ({ currentTabPath }) => ({
    margin: currentTabPath === 'contact' ? pxArrayToRem([-4, 0, 0, 0]) : 0,
    paddingBottom: pxToRem(65),

    [`& .${HeaderClassName}`]: {
        display: currentTabPath === 'contact' ? 'none' : 'block',
    },
});

export const CustomMessage: StyleRule = () => ({
    padding: pxArrayToRem([0, 18, 20, 18]),
});

export const GetInTouchHeaderStyle: StyleRule = ({ theme }) => ({
    fontWeight: 500,
    lineHeight: 1.1,
    fontSize: pxToRem(58),
    color: theme.siteVariables.colorScheme.default?.foreground,
    textTransform: 'uppercase',
    margin: 0,
});

export const Header4Style: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(46.8),
    paddingLeft: pxToRem(2),
    margin: pxArrayToRem([0, 0, 90, 0]),

    [`&:empty`]: {
        display: 'none',
    },
});

export const FormStyle: StyleRule = (_) => ({
    width: pxToRem(897),
    paddingTop: pxToRem(7),
    marginRight: pxToRem(2),

    '& fieldset:first-child': {
        borderTop: '2rem solid',
    },
});

export const MobileGetInTouch: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 18, 0, 18]),

    [`& .${captionClassName}`]: {
        lineHeight: 1.5, //  cannot find it in aria
        color: theme.siteVariables.colorScheme.default?.foreground,
    },

    [`& .${buttonClassName}`]: {
        height: pxToRem(58),
        width: '100%',
        margin: pxArrayToRem([24, 0, 0, 0]),
    },

    '& > div:last-child': {
        [`& .${flexClassName}`]: {
            margin: pxArrayToRem([16, 0, 14, 0]),
            color: theme.siteVariables.colorScheme.default?.foreground,
            [`& .${labelClassName}`]: {
                fontSize: pxToRem(16),
                lineHeight: 1.5,
                width: pxToRem(260),
                letterSpacing: 0,
                color: theme.siteVariables.colorScheme.default?.foreground,
                ...theme.siteVariables.textStyles.Text.Roman55,
            },

            [`& > div:last-child`]: {
                fontSize: pxToRem(16),
                width: pxToRem(100),
                color: theme.siteVariables.colorScheme.default?.foreground,
            },
        },
    },
});

export const MobileGetInTouchSection: StyleRule = () => ({
    marginTop: pxToRem(80),
    [`& .${labelClassName}`]: {
        lineHeight: 1.09,
    },
});

export const MobileEnquiryWrapper: StyleRule = () => ({
    padding: pxArrayToRem([0, 18, 0, 18]),
    '& > div:first-child': {
        paddingTop: '0 !important',
    },
});
