import {
    Container,
    useAppDisplayModeContext,
    useStyles,
    useTheme,
} from '@archipro-design/aria';

import HeaderSection from '../header-section/HeaderSection';

import * as S from './PageLayout.style';
import React from 'react';

import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { GetInTouchSectionMobile } from '../get-in-touch-section/GetInTouchSection.mobile';
import { MobileBreadcrumb } from '../breadcrumb/Breadcrumb.mobile';
import { useLocation } from '@remix-run/react';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import { BannerSegmentEnum } from 'generated/graphql';
import { useMatchesHandleData } from '~/modules/root';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';

const PageLayout: React.FC<
    React.PropsWithChildren & {
        primaryCTA: PrimaryCTAResult;
        awards?: AwardProps[];
        openEnquiryModal: (changeUrl?: boolean) => void;
    }
> = ({ children, primaryCTA, awards, openEnquiryModal }) => {
    const theme = useTheme();
    const { mobile } = useAppDisplayModeContext();

    const disableMobileUserScaling = useMatchesHandleData<boolean>(
        'disableMobileUserScaling',
        false
    );
    const noUserScalable = mobile && disableMobileUserScaling;

    const { css } = useStyles({ noUserScalable });

    const {
        Breadcrumbs: breadcrumbs,
        Banners: banners,
        CompanyLogo: companyLogo,
        LogoBackgroundColor: logoBackgroundColor,
        ID: professionalId,
    } = useProfessionalInitialRouteData();

    const location = useLocation();

    const showEnquiryForm = !location.pathname.includes('contact');

    if (professionalId === undefined) {
        throw new Error('Professional ID can not be undefined.');
    }

    const filteredBanners = banners.filter(
        (b) => b.bannerSegment !== BannerSegmentEnum.Aboutus
    );

    return (
        <div className={css(S.Page)}>
            <Container
                variables={{
                    background:
                        theme.siteVariables.colorScheme.default?.background,
                }}
            >
                <HeaderSection
                    banners={filteredBanners}
                    awards={awards}
                    breadcrumbs={breadcrumbs}
                    companyLogo={companyLogo}
                    professionalId={professionalId}
                    logoBackgroundColor={logoBackgroundColor}
                    primaryCTA={primaryCTA}
                    openEnquiryModal={openEnquiryModal}
                />

                {children}
                {mobile && (
                    <div className={css(S.PageEndWrapper)}>
                        {showEnquiryForm && <GetInTouchSectionMobile />}
                        <MobileBreadcrumb breadcrumbs={breadcrumbs} />
                    </div>
                )}
            </Container>
        </div>
    );
};

export default PageLayout;
