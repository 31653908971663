// import { Image, Avatar, useStyles, useTheme } from '@archipro-design/aria';
import {
    Image,
    Avatar,
    useStyles,
    useTheme,
    Carousel,
    pxToRem,
} from '@archipro-design/aria';

import * as S from './BannerAndLogo.style';
import { useCurrentTabBannerImage } from '../../../hook/use-current-tab-banner-image';
import type SwiperCore from 'swiper';
import {
    MEDIA_CONIDTION_SIZES_LAPTOP_ONLY,
    MEDIA_CONIDTION_SIZES_LARGE_SCREEN,
} from '~/modules/images/config/sizes-constant';
import { useEffect, useRef, useState } from 'react';
import BannerAndLogoHeaderProgress from './BannerAndLogoProgress';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';
import BannerAwards from '@modules/page-builder/component/detail/banner-awards/BannerAwards';
import { hasFeature, useAppData } from '@modules/root';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';

interface BannerAndLogoMobileProps {
    companyLogoURI?: string | null;
    logoBackgroundColor?: string | null;
    professionalID: number;
    banners: ProfessionalBannersData;
    awards?: AwardProps[];
    title: string;
}

let animationTimer = 0;

export const BannerAndLogoMobile = ({
    companyLogoURI = '',
    logoBackgroundColor = 'white',
    banners,
    awards,
    title,
}: BannerAndLogoMobileProps) => {
    const { css } = useStyles();
    const theme = useTheme();
    const bannerImage = useCurrentTabBannerImage(banners);
    const { Link } = useProfessionalInitialRouteData();

    const [headerSwiper, setHeaderSwiper] = useState<SwiperCore | null>(null);
    const ref = useRef(null);

    const { user } = useAppData();
    const enableAwardBadges = hasFeature(user.FeatureFlags, 'award_badges');

    const delay = 4000;
    const updateRate = 500;
    const updateBlock = (updateRate / delay) * 100;

    const [currentTile, setCurrentTile] = useState<number>(0);
    const [currentProgress, setCurrentProgress] = useState<number>(0);

    const startAnimationTimer = () => {
        animationTimer = window.setInterval(() => {
            setCurrentProgress((num) => num + updateBlock);
        }, updateRate);
    };

    // Start animate timer
    useEffect(() => {
        if (animationTimer) {
            window.clearInterval(animationTimer);
        }
        setCurrentProgress(0);
        startAnimationTimer();

        return () => {
            window.clearInterval(animationTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);

    useEffect(() => {
        if (headerSwiper) {
            setCurrentProgress(0);
            setCurrentTile(headerSwiper.realIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerSwiper?.realIndex]);

    return (
        <div className={css(S.Container)}>
            <div className={css(S.BannerWrap)}>
                {banners.length > 1 ? (
                    <Carousel
                        getSwiperApi={(swiper) => {
                            setHeaderSwiper(swiper as SwiperCore);
                        }}
                        ref={ref}
                        aria-roledescription={'carousel'}
                        items={banners.map((img, key) => {
                            const src =
                                img.libraryImageFile?.DetailBannerStandard
                                    ?.Mobile;
                            if (src === null || src === undefined) return null;
                            return (
                                <Image
                                    alt={
                                        key === 0
                                            ? `Hero Banner for ${title}`
                                            : `Supporting Image ${key} for ${title}`
                                    }
                                    key={`bannerImgCarousel${key}`}
                                    src={src}
                                    layout={`fill`}
                                    objectFit={`cover`}
                                    loading={key === 0 ? 'eager' : 'lazy'}
                                    sizes={`${MEDIA_CONIDTION_SIZES_LARGE_SCREEN} 1920px, ${MEDIA_CONIDTION_SIZES_LAPTOP_ONLY} 95vw, 100vw`}
                                    width={414}
                                    height={430}
                                    overlay={{
                                        alwaysVisible: true,
                                        onClick: () => 0,
                                        icon: <></>,
                                        variables: {
                                            backgroundColor:
                                                theme.siteVariables.colors
                                                    .transparency.black['20%'],
                                        },
                                    }}
                                />
                            );
                        })}
                        autoplay={{
                            disableOnInteraction: false,
                            delay,
                        }}
                        navigation={true}
                        loop={true}
                        effect={'fade'}
                        fadeEffect={{
                            crossFade: true,
                        }}
                        hidePaddles={true}
                        design={{
                            top: 0,
                        }}
                        variables={{
                            overlayPadding: pxToRem(0),
                            overlayInitialOpacity: 1,
                            containerWidth: `100%`,
                            containerHeight: '100%',
                        }}
                    />
                ) : (
                    bannerImage && (
                        <Image
                            alt={`Hero Banner for ${title}`}
                            src={bannerImage}
                            width={414}
                            height={358}
                            layout={`fill`}
                            objectFit={`cover`}
                            loading="eager"
                            overlay={{
                                alwaysVisible: true,
                                onClick: () => 0,
                                icon: <></>,
                                variables: {
                                    backgroundColor:
                                        theme.siteVariables.colors.transparency
                                            .black['20%'],
                                },
                            }}
                        />
                    )
                )}
            </div>
            {enableAwardBadges && awards && awards.length > 0 && (
                <div className={css(S.BannerAwardsWrapper)}>
                    <BannerAwards
                        awards={awards}
                        heading={'Award winning professional'}
                        professionalUrl={Link}
                    />
                </div>
            )}
            <div>
                {banners.length > 1 && (
                    <BannerAndLogoHeaderProgress
                        progress={currentProgress}
                        total={banners.length}
                        current={currentTile}
                    />
                )}
            </div>

            <div className={css(S.LogoWrap)}>
                <Avatar
                    image={{
                        src: companyLogoURI ?? '',
                        alt: `${title} company logo`,
                    }}
                    size={150}
                    imageFit={'contain'}
                    variables={{
                        avatarImageBackgroundColor:
                            logoBackgroundColor ?? undefined,
                        borderWidth: '0',
                        borderRadius: '0',
                        avatarBackgroundColor: 'unset',
                    }}
                />
            </div>
        </div>
    );
};
