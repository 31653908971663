import type { Tracker, TrackingEvent } from '@archipro-website/tracker';
import type { MenuLabel, MenuObj } from './utils';

const NAV_MENU_EVENT_MAP: Record<MenuLabel, TrackingEvent | null> = {
    Profile: null,
    About: 'ProfessionalNavigationAboutClick',
    Products: 'ProfessionalNavigationProductClick',
    Projects: 'ProfessionalNavigationProjectClick',
    Articles: 'ProfessionalNavigationArticleClick',
    Videos: 'ProfessionalNavigationVideoClick',
    Network: 'ProfessionalNavigationNetworkClick',
    Contact: 'ProfessionalNavigationContactClick',
};

export const fireNavMenuEvent = (
    menu: MenuObj,
    url: string,
    tracker: Tracker
) => {
    const { label } = menu;
    const event = NAV_MENU_EVENT_MAP[label];
    if (!event) {
        return;
    }

    tracker.log(event, {
        url: new URL(url),
        targetTracker: 'archiproTracker',
    });
};
