import { useStyles } from '@archipro-design/aria';
import * as S from './GetInTouchSection.style';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import CompanyProfile from '~/modules/company-profile/CompanyProfile';

export const GetInTouchSectionMobile = () => {
    const { css } = useStyles();
    const professional = useProfessionalInitialRouteData();
    const { primaryCTA } = professional;

    return (
        <div className={css(S.MobileGetInTouchSection)}>
            <div className={css(S.MobileEnquiryWrapper)}>
                <CompanyProfile
                    showViewProfile={false}
                    data={{
                        Professional: professional,
                        primaryCTA,
                        siteTreeID: professional.ID,
                        itemType: 'professional',
                    }}
                />
            </div>
        </div>
    );
};
