import { Link } from '@remix-run/react';
import type { ProfessionalInitialResult } from '../../hook/use-professional-initial-route-data';

export type MenuLabel =
    | 'Profile'
    | 'About'
    | 'Products'
    | 'Projects'
    | 'Articles'
    | 'Videos'
    | 'Network'
    | 'Contact';

export interface MenuObj {
    link: string;
    label: MenuLabel;
}

interface MenuObjConfig extends MenuObj {
    condition?: boolean;
}

const ALLOWED_ROUTES: {
    [key in Exclude<MenuLabel, 'Profile'> | 'Home']: MenuObjConfig;
} = {
    Home: { link: '/', label: 'Profile' },
    About: { link: '/info', label: 'About' },
    Products: {
        link: '/our-products',
        label: 'Products',
    },
    Projects: {
        link: '/case-studies',
        label: 'Projects',
    },
    Articles: { link: '/journal', label: 'Articles' },
    Videos: { link: '/videos', label: 'Videos' },
    Network: { link: '/partners', label: 'Network' },
    Contact: { link: '/contact', label: 'Contact' },
};

export const getSubRoutes = (
    professional: ProfessionalInitialResult,
    canEdit: boolean,
    linkedProfiles?: null | Array<{
        LibraryLocations: Array<{
            LocationHidden: boolean;
        }>;
    }>
): MenuObj[] => {
    const {
        HasProducts,
        HasProjects,
        HasProjectsUsingThisProfessional,
        HasVideos,
        HasArticles,
        HasPartners,
        ProductsAllowed,
        LibraryLocations,
    } = professional;

    const menu: MenuObjConfig[] = [
        ALLOWED_ROUTES.Home,
        ALLOWED_ROUTES.About,
        {
            ...ALLOWED_ROUTES.Products,
            condition: ProductsAllowed && (canEdit || HasProducts),
        },
        {
            ...ALLOWED_ROUTES.Projects,
            condition: HasProjectsUsingThisProfessional || HasProjects,
        },
        { ...ALLOWED_ROUTES.Articles, condition: HasArticles },
        { ...ALLOWED_ROUTES.Videos, condition: HasVideos },
        { ...ALLOWED_ROUTES.Network, condition: HasPartners },
        {
            ...ALLOWED_ROUTES.Contact,
            condition:
                LibraryLocations.some((l) => !l.LocationHidden) ||
                (linkedProfiles?.some((p) =>
                    p.LibraryLocations.some((l) => !l.LocationHidden)
                ) ??
                    false),
        },
    ];

    return menu.reduce((arr: MenuObj[], m) => {
        if (m.condition || m.condition === undefined) {
            return arr.concat({ link: m.link, label: m.label });
        }
        return arr;
    }, []);
};

export const getProfileNavItems = (
    menuItems: ReturnType<typeof getSubRoutes>,
    currentTabPath: string,
    linkPrefix = '.',
    prefetch: 'render' | 'intent' = 'intent'
) => {
    return menuItems.map((item) => {
        const isActive = currentTabPath === item.link;
        return {
            as: Link,
            prefetch,
            to: `${linkPrefix}${item.link}`.replace(/\/+$/, ''),
            children: item.label,
            selected: isActive,
            key: `${linkPrefix}${item.link}`,
            preventScrollReset: true,
        };
    });
};
