import {
    useStyles,
    Breadcrumb,
    pxToRem,
    useTheme,
} from '@archipro-design/aria';
import * as S from './Breadcrumb.mobile.style';
import { Link } from '@remix-run/react';
import type { Breadcrumb as BreadcrumbType } from '@modules/professional/type/details-root.types';

interface MobileBreadcrumbProps {
    breadcrumbs: BreadcrumbType[];
}
export const MobileBreadcrumb = ({ breadcrumbs }: MobileBreadcrumbProps) => {
    const { css } = useStyles();
    const theme = useTheme();
    const items = breadcrumbs.map((c) => {
        return {
            as: Link,
            to: c.Link,
            label: c.Title,
            key: c.Link + c.Title,
            variables: {
                breadcrumbItemTextColor: theme.siteVariables.colors.grey['300'],
            },
        };
    });

    return (
        <div className={css(S.BreadcrumbWrapper)}>
            <Breadcrumb
                logo={null}
                home={{
                    link: true,
                    as: Link,
                    label: 'Home',
                    to: '/',
                }}
                items={items}
                variables={{
                    breadcrumbFirstChildLeftMargin: pxToRem(0.5),
                    breadcrumbFontSize: theme.siteVariables.fontSizes.caption04,
                }}
            />
        </div>
    );
};
