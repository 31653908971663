import { useAppDisplayModeContext } from '@archipro-design/aria';

import type { Breadcrumb } from '@modules/professional/type/details-root.types';

import { HeaderSectionDesktop } from './HeaderSection.desktop';
import { HeaderSectionMobile } from './HeaderSection.mobile';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';

export interface HeaderSectionProps extends React.PropsWithChildren {
    openEnquiryModal: (changeUrl?: boolean) => void;
    professionalId: number;
    banners?: ProfessionalBannersData;
    awards?: AwardProps[];
    breadcrumbs: Breadcrumb[] | undefined;
    companyLogo: string | undefined;
    logoBackgroundColor: string | undefined;
    primaryCTA: PrimaryCTAResult;
}

const HeaderSection = (props: HeaderSectionProps) => {
    const { desktop } = useAppDisplayModeContext();

    return !desktop ? (
        <HeaderSectionMobile {...props} />
    ) : (
        <HeaderSectionDesktop {...props} />
    );
};

export default HeaderSection;
